import { Box, ChakraProvider, Flex, Heading } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import DesktopPage from "./pages/Desktop";
import HeadsetPage from "./pages/Headset";

export default function App() {
  return (
    <ChakraProvider>
      <Flex flexDir="column">
        <Box flex={1} p={4}>
          <Heading size="lg">Fluid.link</Heading>
        </Box>
        <Routes>
          <Route path="/" element={<DesktopPage />} />
          <Route path="/receiver" element={<HeadsetPage />} />
        </Routes>
      </Flex>
    </ChakraProvider>
  );
}
