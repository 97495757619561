import { DataConnection, Peer } from "peerjs";
import Autosuggest from "react-autosuggest";
import { xkcd_pw_gen_wordlist } from "../passwordgen.js";

import Dropzone from "react-dropzone";

import { DownloadIcon, LinkIcon } from "@chakra-ui/icons";
import {
  Alert,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Spinner,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

function DesktopPage() {
  const { toggleColorMode, colorMode } = useColorMode();
  const toast = useToast();
  const [myId, setMyId] = useState<string | null>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [headsetCode, setHeadsetCode] = useState<string>("");
  const [committedCode, setCommittedCode] = useState<string>("");
  const [connected, setConnected] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [peerConnection, setPeerConnection] = useState<DataConnection | null>(
    null
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [messages, setMessages] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");

  const [file, setFile] = useState<File | null>(null);

  const inputWidth = useBreakpointValue({
    base: "100%",
    md: "300px",
  });

  const onData = useCallback((data) => {
    console.log("data received", data);

    setMessages((messages) => [...messages, data]);
  }, []);

  const myPeer = useMemo(() => {
    console.log("creating new peer");
    const p = new Peer();

    p.on("open", (id) => {
      console.log("peer open", id);
      setMyId(id);
    });

    p.on("connection", (conn) => {
      setPeerConnection(conn);
      conn.on("open", () => {
        console.log("connection received", conn);
        toast({
          title: "A peer has connected",
          description: "A peer has connected to your session.",
          status: "success",
        });
      });

      conn.on("data", (data) => {
        onData(data);
      });
    });
    return p;
  }, [onData, toast]);

  const connection = useMemo(() => {
    if (!committedCode) {
      return null;
    }

    const c = myPeer.connect(committedCode);
    c.on("open", () => {
      console.log("connection opened");
      setConnected(true);

      // Clear the timeout when a connection is successfully established
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    });

    c.on("close", () => {
      setCommittedCode("");
      setHeadsetCode("");
    });

    c.on("data", (data) => {
      onData(data);
    });

    return c;
  }, [myPeer, onData, committedCode]);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const wrap = (children: JSX.Element) => (
    <div>
      <main>
        <Flex
          minW="100vw"
          minH="100vh"
          flexDir={"row"}
          p="8px"
          justifyContent="center"
        >
          <Flex flex={1} flexDir="column" maxW="900px">
            {children}
          </Flex>
        </Flex>
      </main>
    </div>
  );

  const sendMessage = useCallback(() => {
    if (!message) {
      return;
    }

    if (peerConnection) {
      peerConnection.send(message);
    } else {
      connection?.send(message);
    }
    setMessage("");

    // Display toast message
    toast({
      title: "Message sent",
      description: "Your message has been sent.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  }, [connection, message, peerConnection, toast]);

  const sendFile = useCallback(async () => {
    if (file) {
      await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const buffer = e.target?.result;

          if (peerConnection) {
            peerConnection.send({
              file: true,
              data: buffer,
              fileName: file.name,
            });
          } else {
            connection?.send({
              file: true,
              data: buffer,
              fileName: file.name,
            });
          }
          resolve(buffer);
        };
        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(file);
      });
    }
  }, [connection, file, peerConnection]);

  const connectionStatus = useMemo(() => {
    // desktop
    if (committedCode && connection && connected) {
      return connection ? "green" : "red";
    }
  }, [committedCode, connection, connected]);

  useEffect(() => {
    if (colorMode === "dark") {
      return;
    }
    toggleColorMode();
  }, [colorMode, toggleColorMode]);

  useEffect(() => {
    if (committedCode !== "") {
      timeoutRef.current = setTimeout(() => {
        setErrorMessage(
          "Connection failed. Please make sure you are connected to the internet and re-check your connection code."
        );
        setCommittedCode("");
        setHeadsetCode("");
      }, 5000); // 5000 milliseconds = 5 seconds
    }

    // Cleanup function to clear the timeout when the component unmounts or when committedCode changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [committedCode]);

  if (!myId) {
    return wrap(<Spinner />);
  }

  const disconnectButton = (
    <Button
      isDisabled={connectionStatus !== "green"}
      onClick={() => {
        connection?.close();
        setCommittedCode("");
        setPeerConnection(null);
      }}
    >
      Disconnect
    </Button>
  );

  if (connectionStatus === "green") {
    return wrap(
      <Flex flexDir={"column"} gridGap={2}>
        <HStack>
          {disconnectButton}
          <Badge colorScheme={connectionStatus}>
            {connectionStatus === "green" ? "Connected" : "Disconnected"}
          </Badge>
        </HStack>
        <HStack my={8}>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter message"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
          />
          <Button
            onClick={() => {
              sendMessage();
            }}
          >
            Send Data
          </Button>
        </HStack>

        <Dropzone
          onDrop={(acceptedFiles) => {
            setFile(acceptedFiles[0]);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              {file !== null ? (
                <VStack mt={4} flex={1}>
                  <Text>Selected file: {file.name}</Text>

                  <HStack w="100%">
                    <Button
                      onClick={async () => {
                        await sendFile();
                      }}
                      colorScheme="green"
                      disabled={file === null}
                      flex={1}
                    >
                      Send File
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        setFile(null);
                      }}
                    >
                      Remove
                    </Button>
                  </HStack>
                </VStack>
              ) : (
                <Box
                  {...getRootProps()}
                  p={10}
                  border="2px"
                  borderColor="gray.300"
                  borderStyle="dashed"
                  rounded="md"
                  textAlign="center"
                  pos="relative"
                  _hover={{ borderColor: "gray.400" }}
                  transition="background-color 0.2s ease-in-out"
                  cursor="pointer"
                >
                  <input {...getInputProps()} />
                  {!file && (
                    <>
                      <Icon as={DownloadIcon} w={12} h={12} color="gray.400" />
                      <Text mt={2}>
                        Drag and drop some files here, or click to select files
                      </Text>
                    </>
                  )}
                </Box>
              )}
            </>
          )}
        </Dropzone>
      </Flex>
    );
  }

  return wrap(
    <Flex flexDir="column" flex={1}>
      <Flex alignItems={"center"}>
        <Flex
          alignItems={"center"}
          flexDir={["column", "column", "row"]}
          gridGap="6px"
        ></Flex>
      </Flex>

      {committedCode !== "" ? (
        <VStack>
          <Heading>Connecting...</Heading>
          <Spinner />
          <Button
            onClick={() => {
              setCommittedCode("");
              setHeadsetCode("");
            }}
          >
            Cancel
          </Button>
        </VStack>
      ) : (
        <VStack>
          <Button
            onClick={() => {
              window.location.href = "/receiver";
            }}
          >
            Create new connection
          </Button>

          <Text my={4}>--- OR ---</Text>

          <Heading textAlign={"center"}>
            Enter an existing connection code
          </Heading>

          <HStack>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={({ value }) => {
                const lastWord = value.split(" ").pop() || "";
                setSuggestions(
                  xkcd_pw_gen_wordlist.filter((word) =>
                    word.startsWith(lastWord)
                  )
                );
              }}
              onSuggestionsClearRequested={() => setSuggestions([])}
              getSuggestionValue={(suggestion) => {
                const words = headsetCode.split(" ");
                words.pop();
                words.push(suggestion);
                return words.join(" ");
              }}
              theme={{
                input: {
                  width: inputWidth,
                  fontSize: "lg",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  backgroundColor: null,
                },
                suggestionsList: {
                  listStyleType: "none", // This removes the bullets
                  padding: "0",
                  margin: "0",
                  // backgroundColor: "#fff",
                  borderRadius: "4px",
                  overflow: "auto",
                },
                suggestion: {
                  padding: "10px",
                  borderBottom: "1px solid #ccc",
                },
                suggestionHighlighted: {
                  backgroundColor: "#ddd",
                },
              }}
              renderSuggestion={(suggestion) => <div>{suggestion}</div>}
              inputProps={{
                placeholder: "Enter code",
                value: headsetCode,
                onChange: (e, { newValue }) => setHeadsetCode(newValue),
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent the default action
                    setCommittedCode(headsetCode.trim()); // Submit the form
                  }
                },
                width: "300px",
                fontSize: "lg",
                autoCapitalize: "none",
              }}
            />
            <Button
              onClick={() => {
                setCommittedCode(headsetCode.trim());
              }}
            >
              <Box mr={2}>Connect</Box>
              <LinkIcon />
            </Button>
          </HStack>
          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        </VStack>
      )}
    </Flex>
  );
}

export default DesktopPage;
