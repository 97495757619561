import { DataConnection, Peer } from "peerjs";

import { xkcd_pw_gen } from "../passwordgen.js";

import {
  Badge,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Spinner,
  Text,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";

function HeadsetPage() {
  const { toggleColorMode, colorMode } = useColorMode();
  const toast = useToast();

  const [code, setCode] = useState<string>("");
  const [connected, setConnected] = useState<boolean>(false);

  // Generate a code when the component mounts
  useEffect(() => {
    if (!code) {
      setCode(xkcd_pw_gen);
    }
  }, [code]);

  const [peerConnection, setPeerConnection] = useState<DataConnection | null>(
    null
  );

  const [message, setMessage] = useState<string>("");

  // Change to use an array to track files
  const [receivedFiles, setReceivedFiles] = useState<
    Array<{ fileName: string; data: ArrayBuffer }>
  >([]);

  const onData = useCallback((data) => {
    if (data.file) {
      // Add the received file to the state
      setReceivedFiles((prevFiles) => [
        ...prevFiles,
        { fileName: data.fileName, data: data.data },
      ]);
    } else {
      setMessage(data);
    }
  }, []);

  const myPeer = useMemo(() => {
    // const id = uuidv4();
    // setMyId(id);

    if (!code) {
      return;
    }

    console.log("creating new peer");
    const p = new Peer(code);

    p.on("open", (id) => {
      console.log("peer open", id);
    });

    p.on("connection", (conn) => {
      setPeerConnection(conn);
      conn.on("open", () => {
        console.log("connection received", conn);
        setConnected(true);
      });
      conn.on("close", () => {
        console.log("connection closed");
        setConnected(false);
      });

      conn.on("data", (data) => {
        onData(data);
      });
    });
    return p;
  }, [onData, code]);

  const connection = useMemo(() => {
    if (!myPeer) {
      return null;
    }

    const c = myPeer.connect(code);
    c.on("open", () => {
      console.log("connection opened");
    });

    c.on("data", (data) => {
      onData(data);
    });

    return c;
  }, [myPeer, onData, code]);

  // Function to handle file download
  const downloadFile = useCallback(
    (file: { fileName: string; data: ArrayBuffer }) => {
      const blob = new Blob([file.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.fileName || "download";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    []
  );

  const connectionStatus = useMemo(() => {
    return peerConnection && connection && code && connected ? "green" : "red";
  }, [code, connected, connection, peerConnection]);

  const wrap = (children: JSX.Element) => (
    <div>
      <main>
        <Flex
          minW="100vw"
          minH="100vh"
          flexDir={"row"}
          p="8px"
          justifyContent="center"
        >
          <Flex flex={1} flexDir="column" maxW="900px" gridGap={4}>
            {children}

            <Divider />

            <Flex
              flexDir={"column"}
              gridGap={2}
              display={connectionStatus === "green" ? "flex" : "none"}
              overflow={"scroll"}
            >
              <HStack alignSelf={"center"}>
                <Heading textAlign={"center"} size="lg">
                  {receivedFiles.length ? "Files" : "No files received"}
                </Heading>
              </HStack>

              {receivedFiles.map((file, index) => (
                <Flex key={index} flexDir={"column"} alignItems={"center"}>
                  <span>{file.fileName}</span>
                  <Button
                    onClick={() => downloadFile(file)}
                    colorScheme="green"
                  >
                    {file.fileName.endsWith(".kdbx")
                      ? "Load Vault File"
                      : "Download"}
                  </Button>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </main>
    </div>
  );

  useEffect(() => {
    if (colorMode === "dark") {
      return;
    }
    toggleColorMode();
  }, [colorMode, toggleColorMode]);

  const disconnectButton = (
    <Button
      onClick={() => {
        setCode("");
        try {
          connection?.close();
        } catch (e) {}
        try {
          peerConnection?.close();
        } catch (e) {}
      }}
    >
      Disconnect
    </Button>
  );

  if (connectionStatus === "green" && !message) {
    return wrap(
      <Flex flexDir="column">
        <HStack>
          {disconnectButton}
          <Badge colorScheme={connectionStatus}>
            {connectionStatus === "green" ? "Connected" : "Disconnected"}
          </Badge>
        </HStack>
        <VStack>
          <Text>Waiting for you to paste...</Text>
          <Spinner />

          <Button
            onClick={() => {
              window.location.href = "/receiver";
            }}
            colorScheme="red"
          >
            Cancel
          </Button>
        </VStack>
      </Flex>
    );
  }

  if (message !== "") {
    return wrap(
      <Flex flexDir="column">
        <HStack>
          {connectionStatus === "green" ? (
            <>{disconnectButton}</>
          ) : (
            <Button
              onClick={() => {
                window.location.href = "/receiver";
              }}
            >
              Create new connection
            </Button>
          )}

          <Badge colorScheme={connectionStatus}>
            {connectionStatus === "green" ? "Connected" : "Disconnected"}
          </Badge>
        </HStack>

        <Text fontSize={"4em"}>{message}</Text>

        <Button
          onClick={() => {
            // Text to copy
            const text = message;

            // Create temporary element
            const tmp = document.createElement("textarea");

            // Add text content
            tmp.textContent = text;

            // Add to DOM
            document.body.appendChild(tmp);

            // Select text
            tmp.select();

            // Copy text
            document.execCommand("copy");

            // Remove from DOM
            document.body.removeChild(tmp);
            // navigator.clipboard.writeText(message);

            toast({
              title: "Copied to clipboard",
              description: "The message has been copied to your clipboard.",
              status: "success",
              position: "top",
            });
          }}
        >
          COPY
        </Button>
      </Flex>
    );
  }

  return wrap(
    <Flex flexDir="column" flex={1}>
      <Flex alignItems={"center"}>
        <Flex
          alignItems={"center"}
          flexDir={["column", "column", "row"]}
          gridGap="6px"
        >
          <Badge colorScheme={connectionStatus}>
            {connectionStatus === "green" ? "Connected" : "Disconnected"}
          </Badge>
        </Flex>
      </Flex>
      {!code ? (
        <Spinner /> // Show a spinner or some loading state while the code is being generated
      ) : (
        <VStack>
          <Heading textAlign={"center"}>
            Your code is <br />
          </Heading>
          <Text my={4} fontSize="2xl">
            {" "}
            <code>{code}</code>
          </Text>
          <Text my={4}>
            Navigate to{" "}
            <Link href="https://link.fluid.so" isExternal color="teal.500">
              link.fluid.so
            </Link>{" "}
            on the device you'd like to share a password or link from and enter
            this code there to connect.
          </Text>
          <Button
            onClick={() => {
              window.location.href = "/receiver";
            }}
          >
            Get new code
          </Button>
        </VStack>
      )}
    </Flex>
  );
}

export default HeadsetPage;
